import {  SHOW_TEACHER_TRIAL_LIST } from '../constants';

export default (state = false, action) => {
  switch(action.type) {
    case SHOW_TEACHER_TRIAL_LIST:
      const { showTeacherTrialList } = action;
      return showTeacherTrialList;
    default:
      return state;
  }
}
