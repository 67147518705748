import {  SET_LESSON_REQUESTS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_LESSON_REQUESTS:
      const { lessonRequests } = action;
      return lessonRequests;
    default:
      return state;
  }
}
