import {  SET_CALENDAR_RESCHEDULED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_CALENDAR_RESCHEDULED_LESSONS:
      const { calendarRescheduledLessons } = action;
      return calendarRescheduledLessons;
    default:
      return state;
  }
}
