import {  SET_CHAT_ID } from '../constants';

export default (state = {}, action) => {
  switch(action.type) {
    case SET_CHAT_ID:
      const { chatIdSelected } = action;
      return chatIdSelected;
    default:
      return state;
  }
}
