import {  SET_DATE_PICKER_COMPLETED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_DATE_PICKER_COMPLETED_LESSONS:
      const { datePickerCompletedLessons } = action;
      return datePickerCompletedLessons;
    default:
      return state;
  }
}
