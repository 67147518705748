import React, { Component } from 'react';
import './Chat.css';
import classnames from 'classnames'
import { connect } from 'react-redux';
import { setChatId } from '../actions';
import { firebaseDB, firestore } from '../firebase';
import { IoChatboxEllipsesOutline } from "react-icons/io5";

class ChatListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayName: '',
      profilePic: '',
      previewMessage: '',
    }
  }

  componentDidMount() {
    firebaseDB.ref(`userInfo/${this.props.uid}/`).once('value')
    .then(data =>{
      //console.log('data.val() = ', data.val())
      this.setState({
        displayName: data.val().displayName,
        profilePic: data.val().profilePic,
      })
    })
    .catch(error =>{
      console.log('error = ', error)
    })

    this.showLastMessage()
  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  showLastMessage() {
    const chatRef = firestore.collection('chat').doc(`${this.props.chatId}`).collection('messages')

    this.unsubscribe = chatRef.orderBy('time', 'desc').limit(1).onSnapshot((snapshot) => {
      if (snapshot) {
        snapshot.forEach(message => {
          this.setState({previewMessage:message.data().message})
        });
      }
    }, (error) => {
      console.log('err0r = ', error)
    })

  }

  render() {
    return (
      <div
        className='chat-list-item'
        onClick={() => this.props.setChatId({chatId:this.props.chatId, uid:this.props.uid})}
      >
        <img
          src={this.state.profilePic}
          className="Profile-Pic"
          alt={'Profile Pic'}
        />
        <div className='chat-list-item-text-container'>
          <h3>
            {this.state.displayName}
          </h3>
          <h4>
            {this.state.previewMessage}
          </h4>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isJapanese, user, showChat, chatList } = state;

  return {
    isJapanese, user, showChat, chatList,
  }
}

export default connect(mapStateToProps, {setChatId} )(ChatListItem)
