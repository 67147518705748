import {  SET_DATE_PICKER_REQUESTED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_DATE_PICKER_REQUESTED_LESSONS:
      const { datePickerRequestedLessons } = action;
      return datePickerRequestedLessons;
    default:
      return state;
  }
}
