import {  SET_STUDENT_CONSULTATION_LIST } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_STUDENT_CONSULTATION_LIST:
      const { studentConsultaitonList } = action;
      return studentConsultaitonList;
    default:
      return state;
  }
}
