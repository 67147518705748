import {  SHOW_CHAT } from '../constants';

export default (state = false, action) => {
  switch(action.type) {
    case SHOW_CHAT:
      const { showChat } = action;
      return showChat;
    default:
      return state;
  }
}
