import {  SET_CALENDAR_BOOKED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_CALENDAR_BOOKED_LESSONS:
      const { calendarBookedLessons } = action;
      return calendarBookedLessons;
    default:
      return state;
  }
}
