import React from 'react'
import './VideoCall.css'
import { firebaseDB } from '../firebase'
//import { sendChatMessage, chatMessageListener } from './modules/FirebaseModule'


export default class ChatMessageItem extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      messageCount: 0,
    }
  }

  chatMessages(message) {
    return(
      message.map((text, n) => {
        return (
          <label key={n}>
            {text}
          </label>
      )}
    ))
  }

  showChatMessages = () => {
    if (this.props.chatMessages.length > 0) {
      return(
        <div className='chat-messages'>
        {
          this.props.chatMessages.map((item, i) => {
              const lastItem = this.props.chatMessages.length - 1
              var previousUid
              var isSameUid = false
            if (i > 0) {
              previousUid = this.props.chatMessages[i-1].uid
              if (previousUid === item.uid) {
                isSameUid = true
              }
            }

            return(
              <div key={i} className={this.props.user.uid === item.uid ? 'chat-message my-chat-message' : 'chat-message'}>
                {isSameUid ?
                  <div></div>
                  :
                  <div className='chat-message-name'>
                    <label>
                      {this.props.user.uid === item.uid ? this.props.user.displayName : this.props.otherUserInfo.displayName}
                    </label>
                  </div>
                }

                <div className={this.props.user.uid === item.uid ? 'chat-profil-pic-and-text-container my-chat-content' : 'chat-profil-pic-and-text-container'}>
                  {isSameUid ?
                    <div className='empty-profilePic'></div>
                    :
                    <img
                      src={this.props.user.uid === item.uid ? this.props.user.profilePic : this.props.otherUserInfo.profilePic}
                      alt={'ProfilePic'}
                    />
                  }

                  <div className={this.props.user.uid === item.uid ? 'chat-message-text-container my-text-container' : 'chat-message-text-container'}>
                    {this.chatMessages(item.message)}
                  </div>
                </div>
              </div>
            )
          })
        }
        </div>
      )
    } else {
      return(
        <div className='empty-chat-container'>
          <h3>
            Today
          </h3>
          <h4>
            You can chat with each other here
          </h4>
        </div>
    )}
  }

  render () {
    return <div>
      {this.showChatMessages()}
    </div>
  }
}
