import {combineReducers} from 'redux';
import user from './reducer_user';
import lessonRequests from './reducer_lessonRequests';
import bookedLessons from './reducer_bookedLessons';
import calendarBookedLessons from './reducer_calendarBookedLessons'
import calendarRequestedLessons from './reducer_calendarRequestedLessons'
import datePickerRequestedLessons from './reducer_datePickerRequestedLessons'
import datePickerBookedLessons from './reducer_datePickerBookedLessons'
import lessonsAvailable from './reducer_studentLessonsAvailable'
import lessonsInCredit from './reducer_lessonsInCredit'
import lessonsToPay from './reducer_teacherLessonsToPay'
import isJapanese from './reducer_languageSelected'
import rescheduledLessons from './reducer_rescheduledLessons'
import calendarRescheduledLessons from './reducer_calendarRescheduledLessons'
import blockedTimes from './reducer_blockedTimes'
import completedLessons from './reducer_completedLessons'
import datePickerCompletedLessons from './reducer_datePickerCompletedLessons'
import showChat from './reducer_chat'
import chatList from './reducer_chatList'
import chatIdSelected from './reducer_chatId'
import teacherList from './reducer_teacherList'
import showTeacherTrialList from './reducer_showTeacherTrialList'
import studentConsultaitonList from './reducer_studentConsultationList'
import pendingTrialLessonsList from './reducer_pendingTrialLessonsList'
import googleRefreshTokenSaved from './reducer_googleRefreshTokenSaved'

const allReducers = combineReducers ({
  user,
  lessonRequests,
  bookedLessons,
  calendarBookedLessons,
  calendarRequestedLessons,
  calendarRescheduledLessons,
  datePickerRequestedLessons,
  datePickerBookedLessons,
  lessonsAvailable,
  lessonsInCredit,
  lessonsToPay,
  isJapanese,
  rescheduledLessons,
  blockedTimes,
  completedLessons,
  datePickerCompletedLessons,
  showChat,
  chatList,
  chatIdSelected,
  teacherList,
  showTeacherTrialList,
  studentConsultaitonList,
  pendingTrialLessonsList,
  googleRefreshTokenSaved,
})

export default allReducers;
