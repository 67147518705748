import {  STUDENT_LESSONS_AVAILABLE } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case STUDENT_LESSONS_AVAILABLE:
      const { lessonsAvailable } = action;
      return lessonsAvailable;
    default:
      return state;
  }
}
