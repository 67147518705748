import {  IS_JAPANESE } from '../constants';

export default (state = null, action) => {
  switch(action.type) {
    case IS_JAPANESE:
      const { isJapanese } = action;
      return isJapanese;
    default:
      return state;
  }
}
