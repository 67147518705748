export const SIGNED_IN = 'SIGNED_IN';
export const SET_LESSON_REQUESTS = 'SET_LESSON_REQUESTS';
export const SET_BOOKED_LESSONS = 'SET_BOOKED_LESSONS';
export const SET_COMPLETED_LESSONS = 'SET_COMPLETED_LESSONS';
export const SET_CALENDAR_BOOKED_LESSONS = 'SET_CALENDAR_BOOKED_LESSONS';
export const SET_CALENDAR_REQUESTED_LESSONS = 'SET_CALENDAR_REQUESTED_LESSONS';
export const SET_DATE_PICKER_REQUESTED_LESSONS = 'SET_DATE_PICKER_REQUESTED_LESSONS'
export const SET_DATE_PICKER_BOOKED_LESSONS = 'SET_DATE_PICKER_BOOKED_LESSONS'
export const SET_DATE_PICKER_COMPLETED_LESSONS = 'SET_DATE_PICKER_COMPLETED_LESSONS'
export const STUDENT_LESSONS_AVAILABLE = 'STUDENT_LESSONS_AVAILABLE'
export const LESSONS_IN_CREDIT = 'LESSONS_IN_CREDIT'
export const TEACHER_PAYMENT = 'TEACHER_PAYMENT'
export const IS_JAPANESE = 'IS_JAPANESE'
export const SET_RESCHEDULED_LESSONS = 'SET_RESCHEDULED_LESSONS'
export const SET_CALENDAR_RESCHEDULED_LESSONS = 'SET_CALENDAR_RESCHEDULED_LESSONS'
export const SET_BLOCKED_TIMES = 'SET_BLOCKED_TIMES'
export const SHOW_CHAT = 'SHOW_CHAT'
export const SET_CHAT_LIST = 'SET_CHAT_LIST'
export const SET_CHAT_ID = 'SET_CHAT_ID'
export const SET_TEACHER_LIST = 'SET_TEACHER_LIST'
export const SHOW_TEACHER_TRIAL_LIST = 'SHOW_TEACHER_TRIAL_LIST'
export const SET_STUDENT_CONSULTATION_LIST = 'SET_STUDENT_CONSULTATION_LIST'
export const SET_PENDING_TRIAL_LESSONS_LIST = 'SET_PENDING_TRIAL_LESSONS_LIST'
export const GOOGLE_REFRESH_TOKEN = 'GOOGLE_REFRESH_TOKEN'
