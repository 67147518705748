import React, { Component } from 'react';
import './Chat.css';
import '../Home/Home.css';
import '../CSS/Responsiveness.css'
import classnames from 'classnames'
import { connect } from 'react-redux';
import { setChatId } from '../actions';
import { firebaseDB, firestore } from '../firebase';
import ScaleLoader from "react-spinners/ScaleLoader";
import { IoPaperPlaneOutline, IoChevronDownOutline } from "react-icons/io5";
import ChatMessageItem from './ChatMessageItem';

class ChatBox extends Component {

  constructor (props) {
    super(props)
    this.state = {
      message:'',
      displayName: '',
      profilePic: '',
      chatMessages: [],
      messageCount: 0,
      isMac: false,
      loading: false,
      showChat: false,
    }
    this.bottomOfChat = React.createRef()
  }

  componentDidMount() {

    if (!this.state.loading) {
      this.bottomOfChat.current.scrollIntoView({behavior:'smooth'})
    }

    if (navigator.userAgent.indexOf('Mac OS X') != -1) {
      this.setState({isMac:true})
    }

    if (this.props.chatIdSelected.chatId) {
      this.loadChatMessages()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { chatIdSelected } = this.props

    if (prevProps.chatIdSelected.chatId != chatIdSelected.chatId && chatIdSelected.chatId) {
      this.loadChatMessages()
      this.setState({loading:true, showChat:true})
    }

    if (this.state.messageCount != this.state.chatMessages.length && !this.state.loading) {
      this.setState({messageCount:this.state.chatMessages.length})
      this.bottomOfChat.current.scrollIntoView({behavior:'smooth'})
    }

    if (prevState.showChat === true && this.state.showChat === false) {
      this.props.setChatId({})
    }

  }

  componentWillUnmount() {
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }

  loadChatMessages() {
    const { chatId, uid } = this.props.chatIdSelected
    const chatRef = firestore.collection('chat').doc(`${chatId}`).collection('messages')

    this.unsubscribe = chatRef.orderBy('time').onSnapshot((snapshot) => {
      if (snapshot) {
        let chatArray = []
        snapshot.forEach(message => {
          chatArray.push(message.data())
        });
        this.setState({chatMessages:chatArray})
      }
    }, (error) => {
      //...
    })

    firebaseDB.ref(`userInfo/${uid}/`).once('value')
    .then(user =>{
      if (user.val()) {
        this.setState({
          displayName: user.val().displayName,
          profilePic: user.val().profilePic,
          loading:false,
        })
        this.bottomOfChat.current.scrollIntoView({behavior:'smooth'})
      }
    })
    .catch(error =>{
      console.log('error = ', error)
    })

  }

  kepPressed(data) {
    const { message } = this.state
    if (data.metaKey && data.key === 'Enter' && message || data.ctrlKey && data.key === 'Enter' && message || data.type === 'click' && message) {
      const numberOfLineBreaks = (message.match(/\n/g)||[]).length;
      const splittedMessage = message.split(/\r?\n/g);
      const time = new Date().getTime()

      firestore.collection('chat').doc(`${this.props.chatIdSelected.chatId}`).collection('messages').add({
        uid: this.props.user.uid,
        time,
        message: splittedMessage,
      })
      .then(() =>{
        this.setState({message:''})
        this.bottomOfChat.current.scrollIntoView({behavior:'smooth'})
      })
      .catch(error =>{
        console.log('error = ', error)
      })
    }
  }

  showChat() {
    if (this.props.chatIdSelected.chatId) {
      return 'chat-box'
    } else {
      return 'hide-chat-box'
    }
  }

  render() {
    return (
      <div className={this.state.showChat ? 'chat-box' : 'hide-chat-box'}>

        {this.state.loading ?
          <div className='chat-loading'>
            <ScaleLoader />
          </div>
          :
          <div className={this.state.showChat ? 'chat-box' : 'hide-chat-box'}>
            <div className='chat-box-header-container'>
              <img src={this.state.profilePic} alt={'ProfilePic'} />
              <h3>
                {`Chat with ${this.state.displayName}`}
              </h3>
              <div
                className='chat-header-hide-button'
                onClick={() => this.setState({showChat:false})}
              >
                <IoChevronDownOutline />
              </div>
            </div>

            <div className='chat-box-message-container'>
              <ChatMessageItem
                user={this.props.user}
                chatMessages={this.state.chatMessages}
                otherUserInfo={{displayName:this.state.displayName, profilePic:this.state.profilePic}}
              />
              <div ref={this.bottomOfChat}></div>
            </div>

            <div className='chat-box-input-container'>
              <textarea
                value={this.state.message}
                className="form-control"
                type="text"
                style={{marginRight:'5px'}}
                onKeyDown={data => this.kepPressed(data)}
                placeholder={this.props.isJapanese ? "メッセージを入力" : "Write your message..."}
                onChange={(event) => this.setState({message:event.target.value})}
              >
              </textarea>
              <div className="send-button" onClick={(data) => this.kepPressed(data)}>
                <div className="send-icon-container"><IoPaperPlaneOutline color="white" fontSize="1.2em"/></div>
              </div>
            </div>
            <label>
              {this.state.isMac ? 'Cmd + Enter to send' : 'Cltr + Enter or to send'}
            </label>
          </div>
        }

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isJapanese, user, chatIdSelected } = state;

  return {
    isJapanese, user, chatIdSelected,
  }
}

export default connect(mapStateToProps, { setChatId } )(ChatBox)
