import {  SET_BLOCKED_TIMES } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_BLOCKED_TIMES:
      const { blockedTimes } = action;
      return blockedTimes;
    default:
      return state;
  }
}
