import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducer from './reducers';
import './index.css';
import LandingPage from './LandingPage/LandingPage'

const OurStory = React.lazy(() => import('./OurStory/OurStory'))
const SignUp = React.lazy(() => import('./SignUp/SignUp'))
const SignIn = React.lazy(() => import('./SignUp/SignIn'))
const Profiles = React.lazy(() => import('./Profiles/Profiles'))
const ResetPassword = React.lazy(() => import('./SignUp/ResetPassword'))
const Subscription = React.lazy(() => import('./SignUp/Subscription'))
const ProfilePage = React.lazy(() => import('./ProfilePage/ProfilePage'))
const TsAndCs  = React.lazy(() => import('./TsAndCs/TsAndCs'))
const FAQPage = React.lazy(() => import('./FAQ/FAQPage'))
const NotFound = React.lazy(() => import('./404/404'))
const BlogArticle = React.lazy(() => import('./Blog/BlogArticle'))



const store = createStore(reducer);

ReactDOM.render(
  <Provider store={store}>
    <React.Suspense fallback={<span>Loading...</span>}>
      <Router>
        <Switch>
          <Route
            exact path='/'
            component={LandingPage}
            //render={() => < LandingPage/>}
          />
          <Route
            exact path='/ourstory'
            component={OurStory}
            //render={() => < OurStory/>}
          />
          <Route
            exact path='/signup'
            component={SignUp}
            //render={() => < SignUp/>}
          />
          <Route
            exact path='/teachersignup'
            component={SignUp}
            //render={() => < SignUp/>}
          />
          <Route
            exact path='/signin'
            component={SignIn}
            //render={() => < SignIn/>}
          />
          <Route
            exact path='/profiles'
            component={Profiles}
            //render={() => < Profiles/>}
          />
          <Route
            exact path='/resetpassword'
            component={ResetPassword}
            //render={() => < ResetPassword/>}
          />
          <Route
            exact path='/profilepage'
            component={ProfilePage}
            //render={() => < ProfilePage/>}
          />
          <Route
            exact path='/subscription'
            component={Subscription}
            //render={() => < Subscription/>}
          />
          <Route
            exact path='/termsandconditions'
            component={TsAndCs}
            //render={() => < TsAndCs/>}
          />
          <Route
            exact path='/faq'
            component={FAQPage}
            //render={() => < TsAndCs/>}
          />

          <Route
            exact path='/blogarticle'
            component={BlogArticle}
            //render={() => < TsAndCs/>}
          />

          <Route
            path="*"
            component={NotFound}
            //status={404}
          />

        </Switch>
      </Router>
    </React.Suspense>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
