import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import 'firebase/storage';
import 'firebase/functions';
import "firebase/analytics";

const { REACT_APP_FIREBASE, REACT_APP_FIREBASE_APP_ID, REACT_APP_FIREBASE_MEASUREMENT_ID } = process.env;
// Initialize Firebase
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: REACT_APP_FIREBASE,
    authDomain: "lish-3ce2e.firebaseapp.com",
    databaseURL: "https://lish-3ce2e-default-rtdb.firebaseio.com",
    projectId: "lish-3ce2e",
    storageBucket: "lish-3ce2e.appspot.com",
    messagingSenderId: "113574057639",
    appId: REACT_APP_FIREBASE_APP_ID,
    measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
  };

//firebase.initializeApp(firebaseConfig);

//firebase.initializeApp(config);
export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const firebaseDB = firebase.database();
export const storage = firebase.storage();
export var firebaseFunctions = firebase.functions();
export var firestore = firebase.firestore()
export const analytics = firebase.analytics();
//export const provider = new firebase.auth.FacebookAuthProvider();
//export const firebaseStorage = firebase.storage();
//export var firebaseFunctions = firebase.functions();
