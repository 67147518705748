import React, { Component, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import './LandingPage.css'
//import NavBar from '../NavBar/NavBar'
import HeroHeader from './HeroHeader/HeroHeader'
//import ReactPixel from 'react-facebook-pixel';

const OurStory = lazy(() => import('./OurStory/OurStory'))
const WhyLish = lazy(() => import('./WhyLish/WhyLish'))
const LearningIsHelping = lazy(() => import('./LearningIsHelping/LearningIsHelping'))
const OurService = lazy(() => import('./OurService/OurService'))
const LisHFamily = lazy(() => import('./LisHFamily/LisHFamily'))
const Subscriptions = lazy(() => import('./Subscriptions/Subscriptions'))
const FinalCTA = lazy(() => import('./FinalCTA/FinalCTA'))
const FAQ = React.lazy(() => import('../FAQ/FAQ'))
//const Blog = React.lazy(() => import('./Blog/Blog'))

// const advancedMatching = { em: 'luke.leon@lish-eikaiwa.jp' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };

//ReactPixel.init('494926015751372', advancedMatching, options);

class LandingPage extends Component {

  // signUpPixelEvent() {
  //   //console.log('!! signUpPixelEvent !!')
  //   ReactPixel.track('StartTrial');
  // }

  render() {
    return(
      <div className={'landing-page-container'}>
        <Suspense fallback = {<div>Loading...</div>}>
          <HeroHeader history={this.props.history} isJapanese={this.props.isJapanese}/>
          <OurStory history={this.props.history} />
          <WhyLish history={this.props.history} />
          <LearningIsHelping history={this.props.history} />
          <OurService history={this.props.history} />
          <LisHFamily history={this.props.history} />
          <Subscriptions history={this.props.history} />
          {
            //<Blog history={this.props.history} />
          }
          <FAQ history={this.props.history} />
          <FinalCTA history={this.props.history} />
        </Suspense>
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { isJapanese } = state;
  return {
    isJapanese,
  }
}

export default connect(mapStateToProps, null )(LandingPage);
