import React, { Component } from 'react';
import './Chat.css';
import '../Home/Home.css';
import '../CSS/Responsiveness.css'
import classnames from 'classnames'
import { connect } from 'react-redux';
import { setChatList } from '../actions';
import { firebaseDB } from '../firebase';
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import ChatListItem from './ChatListItem'

class ChatList extends Component {

  componentDidMount() {
    this.loadChatList()
  }

  componentWillUnmount() {
    firebaseDB.ref(`userInfo/${this.props.user.uid}/chat`).off()
  }

  loadChatList() {
    if (this.props.user.uid) {
      firebaseDB.ref(`userInfo/${this.props.user.uid}/chat`).on('value', snap =>{
        if (snap.val()) {
          let chatList = [];
          snap.forEach(chat => {
            const uid = chat.key
            const chatId = chat.val()

            chatList.push({uid, chatId})
          })
          this.props.setChatList(chatList)
        } else {
          this.props.setChatList([])
        }
      })
    } else {
      this.props.setChatList([])
    }
  }

  showChatList() {
    const { chatList } = this.props

    if (chatList.length > 0 ) {
      return (
        <div className="row">
          {
            chatList.map((chatItem, index) => {
              return(
                <ChatListItem
                  key={index}
                  uid={chatItem.uid}
                  chatId={chatItem.chatId}
                />
              )
            })
          }
        </div>
      )
    } else {
      return (
        <div className='empty-chat-list'>
          <label>
           Click on this icon
          </label>
          <IoChatboxEllipsesOutline className='chat-icon'/>
          <label>
           to start a chat
          </label>
        </div>
      )
    }
  }

  render() {
    return (
      <div className={this.props.showChat ? 'show-chat-list' : 'hide-chat-list'}>
        {this.showChatList()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isJapanese, user, showChat, chatList } = state;

  return {
    isJapanese, user, showChat, chatList,
  }
}

export default connect(mapStateToProps, { setChatList } )(ChatList)
