import {  SET_CALENDAR_REQUESTED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_CALENDAR_REQUESTED_LESSONS:
      const { calendarRequestedLessons } = action;
      return calendarRequestedLessons;
    default:
      return state;
  }
}
