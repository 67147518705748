import {
  SIGNED_IN,
  SET_LESSON_REQUESTS,
  SET_BOOKED_LESSONS,
  SET_CALENDAR_BOOKED_LESSONS,
  SET_CALENDAR_REQUESTED_LESSONS,
  SET_DATE_PICKER_REQUESTED_LESSONS,
  SET_DATE_PICKER_BOOKED_LESSONS,
  STUDENT_LESSONS_AVAILABLE,
  LESSONS_IN_CREDIT,
  TEACHER_PAYMENT,
  IS_JAPANESE,
  SET_RESCHEDULED_LESSONS,
  SET_BLOCKED_TIMES,
  SET_COMPLETED_LESSONS,
  SET_DATE_PICKER_COMPLETED_LESSONS,
  SET_CALENDAR_RESCHEDULED_LESSONS,
  SHOW_CHAT,
  SET_CHAT_LIST,
  SET_CHAT_ID,
  SET_TEACHER_LIST,
  SHOW_TEACHER_TRIAL_LIST,
  SET_STUDENT_CONSULTATION_LIST,
  SET_PENDING_TRIAL_LESSONS_LIST,
  GOOGLE_REFRESH_TOKEN,
} from '../constants';

export const logUser = (email, displayName, profilePic, phoneNumber, uid, isTeacher, address) => {
  const action = {
    type: SIGNED_IN,
    email,
    displayName,
    profilePic,
    phoneNumber,
    uid,
    isTeacher,
    address
    }
  return action;
}

export function setStudentConsultationList(studentConsultaitonList) {
  const action ={
    type: SET_STUDENT_CONSULTATION_LIST,
    studentConsultaitonList
  }
    return action;
}

export function setPendingTrialLessonsList(pendingTrialLessonsList) {
  const action ={
    type: SET_PENDING_TRIAL_LESSONS_LIST,
    pendingTrialLessonsList
  }
    return action;
}

export function setlessonRequests(lessonRequests) {
  const action ={
    type: SET_LESSON_REQUESTS,
    lessonRequests
  }
    return action;
}

export function setBookedLesson(bookedLessons) {
  const action ={
    type: SET_BOOKED_LESSONS,
    bookedLessons
  }
    return action;
}

export function setCalendarBookedLesson(calendarBookedLessons) {
  const action ={
    type: SET_CALENDAR_BOOKED_LESSONS,
    calendarBookedLessons
  }
    return action;
}

export function setCalendarRequestedLesson(calendarRequestedLessons) {
  const action ={
    type: SET_CALENDAR_REQUESTED_LESSONS,
    calendarRequestedLessons
  }
    return action;
}

export function setDatePickerRequestedLesson(datePickerRequestedLessons) {
  const action ={
    type: SET_DATE_PICKER_REQUESTED_LESSONS,
    datePickerRequestedLessons
  }
    return action;
}

export function setDatePickerBookedLesson(datePickerBookedLessons) {
  const action ={
    type: SET_DATE_PICKER_BOOKED_LESSONS,
    datePickerBookedLessons
  }
    return action;
}

export function setStudentLessonsAvailable(lessonsAvailable) {
  const action ={
    type: STUDENT_LESSONS_AVAILABLE,
    lessonsAvailable
  }
    return action;
}

export function setLessonsInCredit(lessonsInCredit) {
  const action ={
    type: LESSONS_IN_CREDIT,
    lessonsInCredit
  }
    return action;
}

export function setTeacherLessonsToPay(lessonsToPay) {
  const action ={
    type: TEACHER_PAYMENT,
    lessonsToPay
  }
    return action;
}

export const setLanguage = (isJapanese) => {
  const action ={
    type: IS_JAPANESE,
    isJapanese
  }
  return action;
}

export function setRescheduledLesson(rescheduledLessons) {
  const action ={
    type: SET_RESCHEDULED_LESSONS,
    rescheduledLessons
  }
    return action;
}

export function setCalendarRescheduledLesson(calendarRescheduledLessons) {
  const action ={
    type: SET_CALENDAR_RESCHEDULED_LESSONS,
    calendarRescheduledLessons
  }
    return action;
}

export function setBlockedTimes(blockedTimes) {
  const action ={
    type: SET_BLOCKED_TIMES,
    blockedTimes
  }
    return action;
}

export function setCompletedLessons(completedLessons) {
  const action ={
    type: SET_COMPLETED_LESSONS,
    completedLessons
  }
    return action;
}

export function setDatePickerCompletedLesson(datePickerCompletedLessons) {
  const action ={
    type: SET_DATE_PICKER_COMPLETED_LESSONS,
    datePickerCompletedLessons
  }
    return action;
}

export const setShowChat = (showChat) => {
  const action ={
    type: SHOW_CHAT,
    showChat
  }
  return action;
}

export function setChatList(chatList) {
  const action ={
    type: SET_CHAT_LIST,
    chatList
  }
    return action;
}

export function setChatId(chatIdSelected) {
  const action ={
    type: SET_CHAT_ID,
    chatIdSelected
  }
    return action;
}

export function setTeacherList(teacherList) {
  const action ={
    type: SET_TEACHER_LIST,
    teacherList
  }
    return action;
}

export const setShowTeacherTrialList = (showTeacherTrialList) => {
  const action ={
    type: SHOW_TEACHER_TRIAL_LIST,
    showTeacherTrialList
  }
  return action;
}

export function setGoogleRefreshTokenSaved(googleRefreshTokenSaved) {
  const action ={
    type: GOOGLE_REFRESH_TOKEN,
    googleRefreshTokenSaved
  }
    return action;
}
