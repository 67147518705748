import React, { Component, Suspense, lazy } from 'react';
import './HeroHeader.css'
import NavBar from '../../NavBar/NavBar'
import ProgressiveImg from '../../ProgressiveImageComponent/ProgressiveImage'
import heroPlaceholder from '../../Images/LandingPage/HeroHeaderBluredSmall.jpeg'
import lishLogo from '../../Images/LandingPage/LisHLogo.png'
import lishLogoMobile from '../../Images/LandingPage/LisHLogoMobile.png'
import heroImage from '../../Images/LandingPage/HeroHeader.jpeg'
import heroImageIPadMini from '../../Images/LandingPage/HeroHeaderiPadMini.jpeg'
import hereImageIPhone13 from '../../Images/LandingPage/HeroHeaderiPhone13ProMax.jpeg'
import hereImageIPhoneSE from '../../Images/LandingPage/HeroHeaderiPhoneSE.webp'
import { firebaseApp } from '../../firebase';

//import ScaleLoader from "react-spinners/ScaleLoader";

class HeroHeader extends Component {

  logLoginEvent() {
    firebaseApp.analytics().logEvent('login');
  }

  logSignUpEvent() {
    firebaseApp.analytics().logEvent('sign_up');
  }

  render() {
    return(
    <div className='landing-page-hero-section'>

      <NavBar history={this.props.history}/>

      {
        // <ProgressiveImg
        //   placeholderSrc={heroPlaceholder}
        //   src={hereImageIPhoneSE}
        //   className="landing-page-hero-background-image"
        //   alt='teachers'
        //   title='Teachers'
        // />

        // <img
        //   //placeholderSrc={heroPlaceholder}
        //   src={heroImage}
        //   srcSet={`${hereImageIPhoneSE} 427w, ${hereImageIPhone13} 768w, ${heroImageIPadMini} 1024w`}
        //   //srcset={`${hereImageIPhoneSE} 428w, ${hereImageIPhone13} 786w, ${heroImageIPadMini} 1023w, ${heroImage} 1440w`}
        //   //sizes="(max-width: 480px) 100vw,(max-width: 1024px) 50vw, 33vw"
        //   sizes="(max-width: 720w) 720w, (max-width: 720w) 511w, 393w, 214w"
        //   //sizes="(max-width: 56.25em) 20vw, (max-width: 37.5em) 30vw, 300px"
        //   //sizes="100vw"
        //   className="landing-page-hero-background-image"
        //   alt='teachers'
        //   title='Teachers'
        // />
        <picture className="landing-page-hero-background-image">
          <source
            srcSet={`${heroImage}`}
            media="(min-width: 1023px)"
          />
          <source
            srcSet={`${heroImageIPadMini}`}
            media="(min-width: 786px)"
          />
          <source
            srcSet={`${hereImageIPhone13}`}
            media="(min-width: 429px)"
          />
          <img
            src={hereImageIPhoneSE}
            className="landing-page-hero-background-image"
            alt='teachers'
            title='Teachers'
            loading='lazy'
          />
        </picture>

        // <div className="landing-page-hero-background-image">
        //   <ScaleLoader
        //     color={'#FFF'}
        //     //loading={this.state.loading}
        //   />
        // </div>
      }

      <div className='landing-page-hero-section-button-container' >
        {
          // <img
          //   src={lishLogo}
          //   alt='lish logo'
          //   title='LisH Logo'
          // />

          // <ProgressiveImg
          //   placeholderSrc={heroPlaceholder}
          //   src={lishLogo}
          //   alt='lish logo'
          //   title='LisH Logo'
          //   className="lish-logo-image"
          // />

          // <picture>
          //   <source
          //     srcSet={`${lishLogo}`}
          //     media="(min-width: 429px)"
          //   />
          //   <img
          //     src={lishLogoMobile}
          //     alt='lish logo'
          //     title='LisH Logo'
          //     className="lish-logo-image"
          //   />
          // </picture>
        }

        <div className='landing-page-hero-buttons'>
          <button
            onClick={() => {
              this.logSignUpEvent()
              this.props.history.push('/signup')
            }}

          >
            {this.props.isJapanese ? "メンバーになる" : "Become a Member"}
          </button>
          
        </div>
      </div>
      <div className='landing-page-hero-section-text-container'>
        <h2>
          英語を学べば学ぶほど、人助けになる英会話
        </h2>
      </div>

    </div>
    )
  }

}

export default HeroHeader;
