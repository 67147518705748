import {  SET_BOOKED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_BOOKED_LESSONS:
      const { bookedLessons } = action;
      return bookedLessons;
    default:
      return state;
  }
}
