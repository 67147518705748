import {  GOOGLE_REFRESH_TOKEN } from '../constants';

export default (state = false, action) => {
  switch(action.type) {
    case GOOGLE_REFRESH_TOKEN:
      const { googleRefreshTokenSaved } = action;
      return googleRefreshTokenSaved;
    default:
      return state;
  }
}
