import {  SET_COMPLETED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_COMPLETED_LESSONS:
      const { completedLessons } = action;
      return completedLessons;
    default:
      return state;
  }
}
