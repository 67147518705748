import {  SET_DATE_PICKER_BOOKED_LESSONS } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_DATE_PICKER_BOOKED_LESSONS:
      const { datePickerBookedLessons } = action;
      return datePickerBookedLessons;
    default:
      return state;
  }
}
