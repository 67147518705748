import {  LESSONS_IN_CREDIT } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case LESSONS_IN_CREDIT:
      const { lessonsInCredit } = action;
      return lessonsInCredit;
    default:
      return state;
  }
}
