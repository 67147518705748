import {  TEACHER_PAYMENT } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case TEACHER_PAYMENT:
      const { lessonsToPay } = action;
      return lessonsToPay;
    default:
      return state;
  }
}
