import React, { Component } from 'react';
import '../CSS/Responsiveness.css'
import './NavBar.css';
import '../Home/Home.css';
import { firebaseApp, firebaseDB } from '../firebase';
import classnames from 'classnames'
import { connect } from 'react-redux';
import { setLanguage, setShowChat, logUser } from '../actions'
import chatIcon from '../Images/NavBar/ChatDude.png'
import greenChatBubble from '../Images/NavBar/GreenChatBubble.png'
import lishLogo from '../Images/Logo/LogoV1.png'
import ChatList from '../Chat/ChatList'
import ChatBox from '../Chat/ChatBox'
import { BsFillPersonFill } from "react-icons/bs";

class NavBar extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open:false,
      isJapanese: true,
      userHasProfile:false,
    }
  }

  componentDidMount() {
    if (this.props.isJapanese !== null) {
      this.setState({isJapanese:this.props.isJapanese})
    } else {
      this.props.setLanguage(this.state.isJapanese)
    }
    this.checkUserForProfiles()
  }

  setLanguage() {
    this.props.setLanguage(!this.state.isJapanese)
    this.setState({isJapanese:!this.state.isJapanese})
  }

  checkUserForProfiles() {
    firebaseApp.auth().onAuthStateChanged(user =>{
      if (user) {
        firebaseDB.ref(`userInfo/${user.uid}`).once('value')
        .then(data =>{
          if (data.val()) {
            if (data.val().profiles) {
              this.setState({userHasProfile:true})
            }
          }
        })
        .catch(error =>{
          console.log('checkUserForProfiles error = ', error)
        })
      }
    })

  }

  showProfilesIcon() {
    if(this.state.userHasProfile) {
      return(
        <div className='loged-in-widget profiles-icon-box' onClick={() => this.props.history.push('/profiles')}>
          <div className='chat-icon-row-container'>
            <BsFillPersonFill color='#5FC9AD'/>
            <BsFillPersonFill color='#5FC9AD'/>
          </div>
          <div className='chat-icon-row-container'>
            <BsFillPersonFill color='#5FC9AD'/>
            <BsFillPersonFill color='#5FC9AD'/>
          </div>
        </div>
      )
    }
  }

  showProfilePic() {
    const { showChat } = this.props

    if (this.props.user.profilePic) {
      return (
        <li>
        <div className='loged-in-content-container'>
          <div className="profile-pic-button">
            <img src={this.props.user.profilePic}
              alt={"profile pic"}
              onClick={this.props.resetProfilePage ? () => this.props.resetProfilePage() : () => this.gotoProfilePage()}
            />
          </div>

          {this.showProfilesIcon()}

          <div
            className='loged-in-widget chat-icon-box'
            onClick={() => {
              this.props.setShowChat(!showChat)
              console.log('chat button pressed')
            }}
          >
            <BsFillPersonFill color='#5FC9AD' size='27px'/>

            <div className='chat-bubble'>
              <img src={greenChatBubble} alt={"chat"} />
            </div>
          </div>

          <ChatList />

        </div>
        </li>
      )
    }
  }

  gotoProfilePage() {
    this.props.history.push('/profilepage')
  }

  showChatBox() {
    if (this.props.user.uid) {
      return(
        <ChatBox />
      )
    }
  }

  burgerButtonShowChat() {
    if (this.props.showChat) {
      this.props.setShowChat(false)
    }
  }

  signOutUser() {
    if (firebaseApp.auth().currentUser){
      this.props.logUser(
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      )
      firebaseApp.auth().signOut()
      this.props.history.push('/signin')
    }
  }

  logOut() {
    if (this.props.user.uid) {
      return (
        <li onClick={() =>this.signOutUser()}>
          {this.props.isJapanese ? "ログアウト" : "Log Out"}
        </li>
      )
    } else {
      return (
        <li onClick={() =>this.props.history.push('/signin')}>
          {this.props.isJapanese ? "ログイン" : "Login"}
        </li>
      )
    }
  }

  signUp() {
    if (!this.props.user.uid) {
      return (
        <li onClick={() =>this.signOutUser()}>
          <a href="/signup">
          <div className='nav-bar-sign-up-button'>
            {this.props.isJapanese ? "無料登録" : "Sign Up"}
          </div>
          </a>
        </li>
      )
    }
  }

  showLanguageSelector() {
    if (this.props.history.location.pathname === "/ourstory") {
      return (
        <div></div>
      )
    } else {
      return (
        <div className="language-selector-container">
          <div
            className={classnames('language-label-container', {languageSelected: !this.state.isJapanese})}
            onClick={() =>this.setLanguage()}
          >
            <label>Eng</label>
          </div>
          <div
            className={classnames('language-label-container', {languageSelected: this.state.isJapanese})}
            onClick={() =>this.setLanguage()}
          >
            <label>日本語</label>
          </div>
        </div>
      )
    }
  }

  navContainerClass() {
    if (this.props.history.location.pathname === "/") {
      return "nav-container"
    } else if (this.props.history.location.pathname === "/ourstory") {
      return "nav-container"
    } else {
      return "nav-container2"
    }
  }

  render() {
    // console.log('nav props = ', this.props)
    // console.log('location = ', this.props.history.location.pathname)
    const { isJapanese } = this.props
    const { open } = this.state
    return (
      <div>
        <nav>
          <div className={this.navContainerClass()}>
            <div
              className="burger-button"
              onClick={() =>{
                this.setState({open:!open})
                this.burgerButtonShowChat()
              }}
            >
              <div className="burger-layer"></div>
              <div className="burger-layer"></div>
              <div className="burger-layer"></div>
            </div>

            <div className={!open ? 'burger-nav' : 'burger-nav-show'}>
              <div className='nav-lish-logo'>
                <img
                  src={lishLogo}
                  alt='LISH'
                  title='LISH'
                />
              </div>

              <ul className={classnames('main-nav', {burgerNavPadding: !this.props.user.uid})}>

                {this.showProfilePic()}

                <li onClick={() =>this.props.history.push('/')}>
                  LisH
                </li>
                <li onClick={() =>this.props.history.push('/blogarticle')}>
                  {isJapanese ? 'ブログ' : 'Blog'}
                </li>
                <li onClick={() =>this.props.history.push('/faq')}>
                  {isJapanese ? 'よくある質問' : 'FAQ'}
                </li>
                {this.logOut()}
                {this.signUp()}
                <li>
                  {this.showLanguageSelector()}
                </li>
              </ul>
            </div>
          </div>
        </nav>

        {this.showChatBox()}

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { isJapanese, user, showChat } = state;
  return {
    isJapanese, user, showChat,
  }
}

export default connect(mapStateToProps, { setLanguage, setShowChat, logUser } )(NavBar)
