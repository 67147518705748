import {  SET_TEACHER_LIST } from '../constants';

export default (state = [], action) => {
  switch(action.type) {
    case SET_TEACHER_LIST:
      const { teacherList } = action;
      return teacherList;
    default:
      return state;
  }
}
